$(document).ready(function () {
  /*======  jQuery easy scrolls  ======*/

  /*----------  Offerte in URL  ----------*/

  if (/offerte/.test(window.location.href)) {
    $("html, body").animate({
        scrollTop: $("#offerte").offset().top
      },
      1500
    );
  }

  /*----------  Buttons met '#offerte' als HREF click event  ----------*/

  $(`a[href="#offerte"]`).click(function (event) {
    event.preventDefault();

    $("html, body").animate({
        scrollTop: $("#offerte").offset().top
      },
      1500
    );
  });

  /*======  ACTIONS  ======*/

  /*----------  HEADER MENU TOGGLE  ----------*/

  $(".header-menu-button").click(function (event) {
    event.preventDefault();

    $(this).toggleClass("active");
    $(".header-menu").toggleClass("active");
  });

  /*----------  HEADER MENU ITEM CLICK  ----------*/

  $(".header-menu .easing a").click(function (event) {
    event.preventDefault();
    const linkTarget = $(this).attr("href");

    $(".header-menu-button").toggleClass("active");
    $(".header-menu").toggleClass("active");

    $("html, body").animate({
        scrollTop: $(`${linkTarget}`).offset().top
      },
      1500
    );
  });

  $(".footer-menu.easing a").click(function (event) {
    event.preventDefault();
    const linkTarget = $(this).attr("href");

    $(".footer-menu-button").toggleClass("active");
    $(".footer-menu").toggleClass("active");

    $("html, body").animate({
        scrollTop: $(`${linkTarget}`).offset().top
      },
      1500
    );
  });

  /*----------  SOLLICITEER BUTTON // TOGGLE  ----------*/

  $("#solliciteer-trigger").click(function (event) {
    event.preventDefault();
    $("#solliciteer-block").fadeToggle(400);
    setTimeout(function () {
      $("#solliciteer-form").fadeToggle(400);
    }, 400);
  });

  /*----------  DRONE VIDEO CLICK  ----------*/

  $(".btn-video").click(function (event) {
    event.preventDefault();
    const targetVideo = $(this).data("target-video");

    $(".video-block").removeClass("active");
    $(`.video-block[data-target-video="${targetVideo}"]`).addClass("active");
  });

  /*======  SLIDERS  ======*/

  /*----------  RIGHT SIDE SLIDER  ----------*/

  $(".right-side-slider").slick({
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000
  });

  /*----------  PROJECTS SLIDER  ----------*/

  $(".projects-slider .slides").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $(".projects-slider .slider-controls .prev-slide"),
    nextArrow: $(".projects-slider .slider-controls .next-slide"),
    responsive: [{
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3500
        }
      }
    ]
  });

  /*----------  CHECK IF BLENDING IS POSSIBLE  ----------*/
  var supportsBackgroundBlendMode = window.getComputedStyle(document.body).backgroundBlendMode;

  if (supportsBackgroundBlendMode == 'normal') {
    $('.project-block').addClass('blend-supported');
  }
});

/*----------  FILE UPLOUD  ----------*/
var fileNameArr = [];

$('#Inputfield_plan').change(function (e) {
  var fileName = e.target.files[0].name;

  if ($('#Inputfield_plan').val() == "") {
    $(".c-alert__fail").fadeIn();
  } else {
    fileNameArr.push(fileName)
    $(".c-alert__success").fadeIn();
    $(".file-name").html(fileNameArr.join(", "));

    setTimeout(function () {
      $(".c-alert__success").fadeOut();
    }, 5000);
  }
});